import { connect } from "react-redux";
import {
  requestAdminAmcs,
  requestAdminFunds,
  requestAdminCreateSip,
  requestAdminCheckPan,
  requestAdminFolioList,
  requestAdminFolioListSuccess,
  requestAdminFundsSuccess,
} from "src/redux/actions";
import CreateEditSipMandate from "./create-edit-sip-mandate";

const mapStateToProps = (state) => ({
  adminAmcs: state.adminAmcs,
  adminFunds: state.adminFunds,
  folios: state.adminDeposits?.folioList?.folios || [],
  defaultArnNumber: state.adminCredentials?.adminUser?.defaultArnNumber,
  defaultEmployeeCode: state.adminCredentials?.adminUser?.employeeCode,
});

export default connect(mapStateToProps, {
  requestAdminAmcs,
  requestAdminFunds,
  requestAdminCreateSip,
  requestAdminCheckPan,
  requestAdminFolioList,
  requestAdminFolioListSuccess,
  requestAdminFundsSuccess,
})(CreateEditSipMandate);
