import React, { Component } from "react";
import moment from "moment";
import dayjs from 'dayjs';
import * as yup from "yup";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { Form, Formik } from "formik";
import Select from "react-select";
import { Convert, Helpers } from "src/helpers";
import { Stack, Grid, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Checkbox,
  Box, FormControlLabel, IconButton, Button, Switch, FormLabel, FormControl, FormGroup } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import {
  TextInputOne,
  InputSelectOne,
  ButtonOne,
} from "src/components/form-inputs";
import { toast } from "react-toastify";
import styles from "./_create-edit-sip-mandate.module.scss";
import cx from "classnames";
import FundTable from "./FundTable";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';

const initialFund = { fund: { fundError: "" }, amount: "", amountError: "" };
const maxBasketSize = process.env.REACT_APP_MAX_FUND_BASKET_SIZE || 5

class CreateEditSipMandate extends Component {
  state = {
    isModalOpen: false,
    isAmcLoading: false,
    isFundsLoading: false,
    isCreating: false,
    isFoliosLoading: false,
    selectedFund: "",
    selectedAmc: null,
    isPanChecking: false,
    isDisabledFields: true,
    fundData: [initialFund],
    isSubmit: false,
    selectedFolio: {},
    isNameFieldDisabled: false,
    isPanValid: false,
    isFirstTimeCheckedPan: false,
    isISIP: false,
    mandateMax: "",
    weekDay: "",
    selectedDate: this.getMinStartDate(),
    selectedAmcCode: "",
    isValidPan: false,
  };

  validationSchema = yup.object().shape({
    name: yup.string().required("Please enter investors' name.").min(5, "Name must be at least 5 characters").max(40, "Name has a max of 40 characters"),
    pan: yup.string().required("Please enter PAN number").max(10).test({
      name: 'isPanValid',
      skipAbsent: true,
      test(value, ctx) {

        if(!value)
          return true

        const first5 = value.substring(0, 5)
        if(/^[a-zA-Z]+$/.test(first5) == false) { // Match only alphabetic
          return ctx.createError({message: "Please enter a correct PAN"})
        }

        const character4 = value.substring(3, 4)
        if(!(character4 == 'P')){ //fourth position should be P or F
          return ctx.createError({message: "Please enter a correct PAN"})
        }

        const second4 = value.substring(5, 9)
        if(!/^\d+$/.test(second4)) { // Match only numeric
          return ctx.createError({message: "Please enter a correct PAN"})
        }

        const last1 = value.substring(9, 10)
        if(!/^[a-zA-Z]+$/.test(last1)) { // Match only alphabetic
          return ctx.createError({message: "Please enter a correct PAN"})
        }

        return true
      }
    }),
    email: yup.string().email('Invalid email'),
    phoneNumber: yup.string().min(10, 'Phone number must be at least 10 digits').max(10).matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: true }),
    frequency: yup.string().required('Please select a frequency')
  });

  componentDidMount() {
    this.folioListSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_FOLIO_LIST,
      () => {
        this.setState({ isFoliosLoading: true });
      }
    );

    this.folioListSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FOLIO_LIST_SUCCESS,
      () => this.setState({ isFoliosLoading: false })
    );

    this.folioListFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FOLIO_LIST_FAILED,
      () => this.setState({ isFoliosLoading: false })
    );

    this.amcSubscription = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_AMCS, () => {
      this.setState({ isAmcLoading: true });
      this.formik.setFieldValue("fund", "");
    });

    this.amcSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_AMCS_SUCCESS,
      (data) => {
        this.setState({isAmcLoading: false, selectedAmc: {label: data[0].name, value: data[0].code }})
        this.props.requestAdminFunds({ amc_code: data[0].code });
      }
    );

    this.amcFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_AMCS_FAILED,
      () => this.setState({ isAmcLoading: false })
    );

    this.fundSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_FUNDS,
      () => {
        this.setState({ isFundsLoading: true });
      }
    );

    this.fundSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FUNDS_SUCCESS,
      () => this.setState({ isFundsLoading: false })
    );

    this.fundFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FUNDS_FAILED,
      () => this.setState({ isFundsLoading: false })
    );

    this.createSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_CREATE_SIP,
      () => {
        this.setState({ isCreating: true });
      }
    );

    this.createSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_CREATE_SIP_SUCCESS,
      () =>
        this.setState(
          { isCreating: false, selectedFolio: {},
          fundData: [initialFund], isISIP: false, selectedDate: dayjs().add(7, 'days'),
          mandateMax: ""},
          this.props.handleOnCompleteCreateSip
        )
    );

    this.createFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_CREATE_SIP_FAILED,
      () => this.setState({ isCreating: false })
    );

    this.panCheckSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_CHECK_PAN,
      () => {
        this.setState({ isPanChecking: true });
        this.formik.setFieldValue("name", "")
        this.formik.setFieldValue("email", "")
        this.formik.setFieldValue("phoneNumber", "")
      }
    );

    this.panCheckSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_CHECK_PAN_SUCCESS,
      (data) => {
        if (data?.existingInvestor) {
          toast.success("PAN Verified successfully", "success");
          this.formik.setFieldValue("name", data?.name);
        } else {
          toast.error("PAN verified failed", "error");
          this.props.requestAdminFolioListSuccess([]);
        }
        this.setState({
          isPanChecking: false,
          isDisabledFields: data?.existingInvestor ? false : true,
          isPanValid: data?.existingInvestor ? true : false,
          isNameFieldDisabled: data?.name ? true : false,
          isFirstTimeCheckedPan: true,
        });
      }
    );

    this.panCheckSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_CHECK_PAN_FAILED,
      (data) => {
        toast.error("Pan verfied failed", "error");
        this.props.requestAdminFolioListSuccess([]);
        this.setState({
          isPanChecking: false,
          isDisabledFields: true,
          isNameFieldDisabled: false,
          isPanValid: false,
        });
      }
    );

    this.props.requestAdminFolioListSuccess([]);

    document.addEventListener(
      "keydown",
      (e) => this.escFunction(e, this),
      false
    );
  }

  componentWillUnmount() {
    this.amcSubscription();
    this.amcSuccessSubscription();
    this.amcFailureSubscription();

    this.fundSubscription();
    this.fundSuccessSubscription();
    this.fundFailureSubscription();

    this.createSubscription();
    this.createSuccessSubscription();
    this.createFailureSubscription();

    this.panCheckSubscription();
    this.panCheckSubscriptionSuccess();
    this.panCheckSubscriptionFailure();

    this.folioListSubscription();
    this.folioListSuccessSubscription();
    this.folioListFailureSubscription();

    document.removeEventListener(
      "keydown",
      (e) => this.escFunction(e, this),
      false
    );
  }

  componentDidUpdate() {
    const { isModalOpen } = this.state;
    if (this.props.show && !isModalOpen) {
      this.setState({ isModalOpen: true }, this.props.requestAdminAmcs);
    }
  }

  escFunction(event, props) {
    if (event.key === "Escape" && props?.props?.handleClose) {
      this.setState({selectedFolio: {}, fundData: [initialFund],
        isISIP: false, selectedDate: dayjs().add(7, 'days'),
        mandateMax: ""})
      props?.props?.handleClose();
    }
  }

  handleChooseAmc = (selectedValue) => {
    this.setState({selectedAmc: selectedValue})
    if (selectedValue) {
      this.props.requestAdminFunds({ amc_code: selectedValue.value });
    } else {
      this.setState({ fundData: [initialFund] });
      this.props.requestAdminFundsSuccess([]);
    }
  };

  validatePan = (value) => {
    if(!value) {
      this.setState({isValidPan: false})
      return
    }

    const first5 = value.substring(0, 5)
    if(/^[a-zA-Z]+$/.test(first5) == false) { // Match only alphabetic
      this.setState({isValidPan: false})
      return
    }

    const character4 = value.substring(3, 4)
    if(!(character4 == 'P')){ //fourth position should be P or F
      this.setState({isValidPan: false})
      return
    }

    const second4 = value.substring(5, 9)
    if(!/^\d+$/.test(second4)) { // Match only numeric
      this.setState({isValidPan: false})
      return
    }

    const last1 = value.substring(9, 10)
    if(!/^[a-zA-Z]+$/.test(last1)) { // Match only alphabetic
      this.setState({isValidPan: false})
      return
    }

    this.setState({isValidPan: true})
  }

  handleSubmit = (formValues) => {
    let { name, pan, frequency, distributorCode, employeeCode } = formValues || {};
    const { adminAmcs } = this.props;
    this.setState({ isSubmit: true }, () => {
      const { fundData, selectedFolio, isISIP, selectedAmc, selectedDate } =
        this.state;
      const areAnyFundsRegular = _.find(fundData, (f) => f.fund.isRegularScheme === true)
      if(!areAnyFundsRegular)
        distributorCode = null

      const dateStr = selectedDate.format("YYYY-MM-DD")
      const weekDay = this.getWeeklyDayNumber(dateStr);
      const amcCode = selectedAmc.value
      const getDate = moment(dateStr, "YYYY-MM-DD");
      const getDay = getDate.format("D");
      const dayOfsip = frequency == "weekly" ? weekDay : getDay;
      let { isError } = this.handleFundListErrors();
      isError = this.isSipDayError(selectedDate);
      if (isError) return;

      this.props.requestAdminCreateSip({
        one_click_checkout: {
          onboarding: {
            name,
            pan_number: pan,
            amc_code: amcCode,
            email: selectedFolio?.email,
            phone_number: selectedFolio?.phoneNumber,
          },
          account: {
            folio_number: selectedFolio?.folioNumber,
            amc_code: amcCode,
          },
          sips: fundData.map((d) => ({
            amc_code: amcCode,
            fund_code: d?.fund?.code,
            amount: d?.amount,
            arn_number: distributorCode,
            employee_code: employeeCode,
          })),
          employee_code: employeeCode,
          sip_day: dayOfsip,
          frequency: frequency,
          start_date: dateStr,
          is_isip: isISIP,
          default_mandate_amount: this.state.mandateMax,
          bank_account: {
            account_number: selectedFolio?.bankAccounts?.[0]?.accountNumber,
            ifsc_code: selectedFolio?.bankAccounts?.[0]?.ifscCode,
            bank_name: selectedFolio?.bankAccounts?.[0]?.bankName,
          },
        },
      });
    });
  };
  handleFundListErrors = () => {
    const { fundData, isSubmit } = this.state;
    const fundList = fundData.map((data) => {
      let amountError = data?.amount ? "" : "Please enter amount";
      if (
        data?.amount &&
        data?.fund?.minimumSipAmount &&
        Number(data?.amount) < Number(data?.fund?.minimumSipAmount)
      ) {
        amountError = `Minimum amount to be invested is ${Convert.toCurrencyValue(
          data?.fund?.minimumSipAmount || 0
        )}`;
      }

      let fundError = null
      if(!data?.fund?.schemeName)
        fundError = "Please select fund name"
      else if(!data?.fund?.planType)
        fundError = "Please select plan type"
      else if(!data?.fund?.planOption)
        fundError = "Please select plan option"

      return {
        ...data,
        fund: {
          ...data?.fund,
          fundError: fundError,
        },
        amountError: amountError,
      };
    });
    this.setState({ fundData: fundList });
    if (isSubmit) {
      return {
        isError: fundList.some(
          (data) => data?.fund?.fundError || data?.amountError
        ),
      };
    } else {
      return { isError: false };
    }
  };

  handleAddFundList = () => {
    const { fundData } = this.state;
    if (fundData.length >= maxBasketSize) {
      toast.error("Limit reached");
    }
    else {
      this.setState({ fundData: [...fundData, initialFund] });
    }
  };

  handleRemoveFundList = (index) => {
    const { fundData } = this.state;
    const filteredFundData = fundData.filter((d, i) => i !== index);
    this.setState({ fundData: filteredFundData });
  };

  handleSelectedFolio = (selectedFolio) => {
    this.setState({ selectedFolio });
  };

  verifyPan = () => {
    this.props.requestAdminFolioListSuccess([]);
    this.handleSelectedFolio({});
    if (this.formik.values?.pan && this.formik.values?.pan.length === 10) {
      this.props.requestAdminCheckPan({ pan_number: this.formik.values?.pan });
      this.props.requestAdminFolioList({
        account: { pan_number: this.formik.values?.pan },
      });
    }
    else {
      toast.error("Please enter valid pan number")
    }
  };

  switchISIP = () => {
    this.setState({ isISIP: !this.state.isISIP});
  };

  getWeeklyDayNumber = (selectedDate) => {
    return moment(selectedDate).day();
  };

  handleStartDateChange = (selectedDate) => {
    this.setState({ selectedDate: selectedDate });
  };

  shouldDisableDate = (date) => {
    const frequency = this.formik?.values?.frequency
    let shouldDisableDate = date.date() == 29 || date.date() == 30 || date.date() == 31
    if(frequency == 'weekly' || frequency == 'daily') {
      shouldDisableDate = shouldDisableDate || date.day() == 0 || date.day() == 6
    }

    return shouldDisableDate
  }

  isSipDayError = (date) => {
    const shouldDisable = this.shouldDisableDate(date)
    if(shouldDisable)
      toast.error("Please enter a valid SIP start date", "error");
    return shouldDisable
  }

  getMinStartDate() {
    let minStartDate = dayjs().add(process.env.REACT_APP_MINIMUM_SIP_START_DATE_DELAY_DAYS || 7, 'days')

    if(minStartDate.date() == 29 || minStartDate.date() == 30 || minStartDate.date() == 31)
      minStartDate = minStartDate.add(1, 'month').startOf('month');

    return minStartDate
  }

  render() {
    const { show, adminAmcs, adminFunds, folios } = this.props;
    const {
      isFundsLoading,
      isAmcLoading,
      isCreating,
      isPanChecking,
      selectedFolio,
      isNameFieldDisabled,
      isPanValid,
      isFirstTimeCheckedPan,
      isFoliosLoading,
    } = this.state;
    const fundData = this.state.fundData.slice(0, 3);

    const areAnyFundsRegular = _.find(fundData, (f) => f.fund.isRegularScheme === true)

    return (
      <Dialog
        open={show}
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          this.setState({selectedFolio: {}, fundData: [initialFund],
            isISIP: false, selectedDate: dayjs().add(7, 'days'),
            mandateMax: ""})
          this.props.handleClose();
        }}
      >
        <DialogTitle>New SIP Transaction Link</DialogTitle>
        <IconButton
          onClick={this.props.handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Formik
          initialValues={{
            name: "",
            phoneNumber: "",
            email: "",
            pan: "",
            folio: "",
            amc: "",
            fund: "",
            amount: "",
            frequency: "monthly",
            startDate: "",
            distributorCode: this.props.defaultArnNumber,
            employeeCode: this.props.defaultEmployeeCode,
          }}
          validationSchema={this.validationSchema}
          onSubmit={this.handleSubmit}
          innerRef={(ref) => (this.formik = ref)}
        >
          <Form>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextInputOne
                    name="pan"
                    id="pan"
                    label="PAN"
                    type="text"
                    placeholder="ABCDE1234C"
                    minLength="10"
                    inputType="panNumber"
                    onChange={(event) => {
                      this.formik.setFieldValue("pan", event.target.value);
                      this.validatePan(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ButtonOne
                    type="submit"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "25px",
                    }}
                    disabled={isPanChecking || !this.state.isValidPan}
                    onClick={this.verifyPan}
                  >
                    {isPanChecking ? "Loading..." : "Search"}
                  </ButtonOne>
                </Grid>
              </Grid>
              {isFirstTimeCheckedPan && (
                <TextInputOne
                  name="name"
                  id="name"
                  label="Name of Customer"
                  type="text"
                  max={40}
                  min={5}
                  placeholder="Name of Customer"
                  inputType="personsName"
                  disabled={isNameFieldDisabled}
                  containerstyles={{ marginTop: "8px" }}
                />
              )}

              {!isPanValid && isFirstTimeCheckedPan && (
                <>
                  <TextInputOne
                    name="email"
                    id="email"
                    label="Email"
                    type="text"
                    placeholder="Email"
                    inputType="email"
                    containerstyles={{ marginTop: "8px" }}
                  />

                  <TextInputOne
                    name="phoneNumber"
                    id="phoneNumber"
                    label="Phone Number"
                    type="text"
                    placeholder="Phone Number"
                    inputType="phoneNumber"
                    containerstyles={{ marginTop: "8px" }}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/gi, '').slice(0, 10);
                      this.formik.setFieldValue('phoneNumber', e.target.value)
                    }}
                  />
                </>
              )}

              <div className={styles["divider"]} />

              <FundTable
                isFoliosLoading={isFoliosLoading}
                folios={folios}
                selectedFolio={selectedFolio}
                handleSelectedFolio={this.handleSelectedFolio}
              />

              <div className={styles["divider"]} />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    className={styles["label"]}
                    style={{ marginBottom: 5 }}
                  >
                    Choose Funds
                  </div>
                  <Select
                    placeholder="Choose an AMC"
                    isDisabled={isAmcLoading || adminAmcs?.length == 1}
                    noOptionsMessage={() => "No results found"}
                    isLoading={isAmcLoading}
                    className={styles["fundlable"]}
                    onChange={this.handleChooseAmc}
                    value={this.state.selectedAmc}
                    options={
                      adminAmcs.map((amc, index) => (
                        {label: amc.name, value: amc.code}
                      ))
                    }
                  />
                </Grid>
              </Grid>
              {
                fundData?.map((fData, index) => {
                  const fundsGroupedBySchemeName = _.groupBy(adminFunds, 'schemeName')

                  const selectedSchemeNames = _.map(fundData, (data) => data.fund.schemeName)
                  const availableSchemesBySchemeName = _.pickBy(fundsGroupedBySchemeName, (allSchemes, schemeName) => {
                    return _.indexOf(selectedSchemeNames, schemeName) < 0
                  });

                  const selectedSchemeName = fundData[index]?.fund?.schemeName
                  let planTypes = []
                  let planOptions = []
                  if(selectedSchemeName) {
                    planTypes = _.uniq(_.map(_.values(fundsGroupedBySchemeName[selectedSchemeName]), 'planTypeName'))
                    planOptions = _.uniq(_.map(_.values(fundsGroupedBySchemeName[selectedSchemeName]), 'planOptionName'))
                  }

                  return (
                    <Grid container spacing={1} style={{marginTop: '12px'}}>
                      <Grid item xs={10} sm={11}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          <div style={{flex: 2}}>
                            <Select
                              className={styles["fundlable"]}
                              isDisabled={isAmcLoading || isFundsLoading}
                              isLoading={isAmcLoading || isFundsLoading}
                              options={_.keys(availableSchemesBySchemeName).map((schemeName) => ({
                                label: schemeName,
                                value: schemeName,
                              }))}
                              styles={{
                                valueContainer: (baseStyles, state) => ({
                                  ...baseStyles,
                                  minHeight: '54px',
                                }),
                              }}
                              noOptionsMessage={() => "No results found"}
                              placeholder="Choose a scheme to invest in"
                              value={selectedSchemeName ? { value: selectedSchemeName, label: selectedSchemeName } : null}
                              onChange={(e) => {
                                const selectedGroup = fundsGroupedBySchemeName[e.value]
                                const fund = {
                                  schemeName: e.value,
                                  selectedGroup,
                                };
                                fundData[index] = {
                                  fund,
                                  amount: process.env.REACT_APP_DEFAULT_LUMPSUM_AMOUNT,
                                };

                                this.setState( { fundData }, this.handleFundListErrors);
                              }}
                            />

                            {
                              selectedSchemeName &&
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} style={{marginTop: '8px'}}>
                                  <div style={{flex: 1}}>
                                    <Select
                                      className={styles["fundlable"]}
                                      options={_.map(planTypes, (planTypeName) => ({
                                        label: planTypeName,
                                        value: planTypeName,
                                      }))}
                                      styles={{
                                        valueContainer: (baseStyles, state) => ({
                                          ...baseStyles,
                                          minHeight: '54px',
                                        }),
                                      }}
                                      noOptionsMessage={() => "No results found"}
                                      placeholder="Choose a plan type"
                                      value={fundData[index]?.fund?.planType ? { value: fundData[index]?.fund?.planType, label: fundData[index]?.fund?.planType } : null}
                                      onChange={(e) => {
                                        const fundDataAtCurrentIndex = fundData[index].fund
                                        const planType = e.value
                                        const planOption = fundDataAtCurrentIndex.planOption
                                        let chosenFund = {}
                                        if(planOption) {
                                          chosenFund = _.find(fundDataAtCurrentIndex.selectedGroup, (groupFund) => planType == groupFund.planTypeName && planOption == groupFund.planOptionName)
                                          if(!chosenFund) {
                                            toast.error("Fund not found. Please try another one", "error");
                                            return
                                          }
                                        }

                                        fundData[index].fund = {
                                          ...fundDataAtCurrentIndex,
                                          ...chosenFund,
                                          planType: planType,
                                        };

                                        this.setState( { fundData }, this.handleFundListErrors);
                                      }}
                                    />
                                  </div>

                                  <div style={{flex: 1}}>
                                    <Select
                                      className={styles["fundlable"]}
                                      options={_.map(planOptions, (planOptionName) => ({
                                        label: planOptionName,
                                        value: planOptionName,
                                      }))}
                                      styles={{
                                        valueContainer: (baseStyles, state) => ({
                                          ...baseStyles,
                                          minHeight: '54px',
                                        }),
                                      }}
                                      noOptionsMessage={() => "No results found"}
                                      placeholder="Choose a plan option"
                                      value={fundData[index]?.fund?.planOption ? { value: fundData[index]?.fund?.planOption, label: fundData[index]?.fund?.planOption } : null}
                                      onChange={(e) => {
                                        const fundDataAtCurrentIndex = fundData[index].fund
                                        const planType = fundDataAtCurrentIndex.planType
                                        const planOption = e.value
                                        let chosenFund = {}
                                        if(planType) {
                                          chosenFund = _.find(fundDataAtCurrentIndex.selectedGroup, (groupFund) => planType == groupFund.planTypeName && planOption == groupFund.planOptionName)
                                          if(!chosenFund) {
                                            toast.error("Fund not found. Please try another one", "error");
                                            return
                                          }
                                        }

                                        fundData[index].fund = {
                                          ...fundDataAtCurrentIndex,
                                          ...chosenFund,
                                          planOption: e.value
                                        };

                                        this.setState( { fundData }, this.handleFundListErrors);
                                      }}
                                    />
                                  </div>
                                </Stack>
                            }
                            <div className={styles["fundname-error"]}>
                              {fData?.fund?.fundError}
                            </div>
                          </div>
            
                          <div
                            key={index}
                            className={cx(styles["fund-amount"], styles["amtwrap"])}
                            style={{flex: 1, alignSelf: 'center'}}
                          >
                            <TextInputOne
                              name="amount"
                              id="amount"
                              type="text"
                              placeholder="Enter Amount to be invested"
                              inputType="amount"
                              value={fData?.amount}
                              onChange={(e) => {
                                fundData[index] = {
                                  ...fundData[index],
                                  amount: e.target.value,
                                };
                                this.setState(
                                  { fundData },
                                  this.handleFundListErrors
                                );
                              }}
                            />
                            <div className={styles["fundname-error"]}>
                              {fData?.amountError}
                            </div>
                          </div>
                        </Stack>
                      </Grid>
                      <Grid item xs={2} sm={1} style={{alignSelf: 'center'}}>
                        <div className={cx(styles["add-fund"])}>
                          {index === fundData.length - 1 && fundData.length < maxBasketSize ? (
                            <div
                              key={index}
                              onClick={this.handleAddFundList}
                              className={styles["add-btn"]}
                            >
                              +
                            </div>
                          ) : (
                            <div
                              className={styles["remove-btn"]}
                              key={index}
                              onClick={() => this.handleRemoveFundList(index)}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )
                })
              }

              <div className={styles["divider"]} />

              <Grid container spacing={2}>
                { areAnyFundsRegular &&
                  <Grid item xs={6}>
                    <TextInputOne
                      variant="outlined"
                      fullWidth
                      name="distributorCode"
                      id="distributorCode"
                      label="Distributor code (ARN/RIA)"
                      type="text"
                      maxLength="10"
                      placeholder="ARN-01234"
                      inputType="distributorCode"
                      onChange={(event) => {
                        this.formik.setFieldValue(
                          "distributorCode",
                          event.target.value
                        );
                        this.setState({ distributorCode: event.target.value });
                      }}
                    />
                  </Grid>
                }

                <Grid item xs={areAnyFundsRegular ? 6 : 12}>
                  <div
                    className={styles["label"]}
                    style={{ marginBottom: 5 }}
                  >
                    Employee code
                  </div>
                  <TextInputOne
                    variant="outlined"
                    fullWidth
                    name="employeeCode"
                    id="employeeCode"
                    type="text"
                    maxLength="10"
                    placeholder="AAA-BBB"
                    sizeType="medium"
                    inputType="employeeCode"
                    onChange={(event) => {
                      this.formik.setFieldValue(
                        "employeeCode",
                        event.target.value
                      );
                      this.setState({ employeeCode: event.target.value });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputSelectOne
                    label="Choose frequency of SIP"
                    name="frequency"
                    id="frequency"
                  >
                    <option value="monthly">Monthly</option>
                    <option value="weekly">Weekly</option>
                    <option value="daily">Daily</option>
                  </InputSelectOne>
                </Grid>
                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Choose start date of SIP
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      slotProps={{layout: {
                        sx: {
                          '.Mui-selected:hover': {
                            backgroundColor: (process.env.REACT_APP_SIDEBAR_COLOR || '#000') + ' !important',
                          },
                          '.MuiPickersDay-root.Mui-selected': {
                            backgroundColor: (process.env.REACT_APP_SIDEBAR_COLOR || '#000') + ' !important',
                          },
                          '.MuiButton-root': {
                            color: (process.env.REACT_APP_SIDEBAR_COLOR || '#000') + ' !important',
                          },
                          'MuiButtonBase-root': {
                            color: (process.env.REACT_APP_SIDEBAR_COLOR || '#000') + ' !important',
                          }
                        }}
                      }} 
                      sx={{'width': '100%',}}
                      name="startDate"
                      id="startDate"
                      value={this.state.selectedDate}
                      minDate={this.getMinStartDate()}
                      maxDate={dayjs().add(90, 'days')}
                      shouldDisableDate={(date) => this.shouldDisableDate(date)}
                      onChange={this.handleStartDateChange}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Should the first installment be paid right away?</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={this.switchISIP}
                            checked={this.state.isISIP}
                          />
                        }
                        label={this.state.isISIP ? "Yes" : "No"}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <div className={cx(styles["fund-amount"], styles["amtwrap"])}>
                    <TextInputOne
                      name="mandateMax"
                      id="mandateMax"
                      type="number"
                      placeholder="20,000"
                      label="Enter default mandate max (optional)"
                      inputType="amount"
                      value={this.state.mandateMax}
                      onChange={(e) => {
                        this.setState({ mandateMax: parseInt(e.target.value) });
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center'}}>
              <Button
                type="submit"
                size="large"
                disabled={isCreating}
                style={{background: 'black'}}
                variant="contained"
                // onClick={() => handleSubmit()}
                startIcon={<CreateIcon />}>
                  {isCreating
                    ? "Loading..."
                    : "Generate Link"}
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    );
  }
}

export default CreateEditSipMandate;
