import React, { useMemo, useState } from "react";
import moment from "moment";
import { DataTable } from "../../../../../components";

const API_ENDPOINT_URL = process.env.API_ENDPOINT_URL || "https://surface.thesavvyapp.in";

const LandingPagesTable = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, handlePaginationChange } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <strong> Landing page name </strong>,
        accessor: "landingPageName",
        id: "landingPageName",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              onClick={() => handleRowClick(row)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: <strong> Created at </strong>,
        accessor: "createdAt",
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <div
            onClick={() => handleRowClick(row)}
            style={{ cursor: "pointer", width: "100%" }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: <strong> UUID </strong>,
        accessor: "uuid",
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <div
            onClick={() => handleRowClick(row)}
            style={{ cursor: "pointer", width: "100%" }}
          >
            {value}
          </div>
        ),
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    setSelectedRowData(row?.original)
    props.onRowSelected(row)
  }

  const handleRowData = (row) => {

  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
      />
      <input id="textcopy" className="link-input" style={{ display: "none" }} />
    </>
  );
};

export default LandingPagesTable;
